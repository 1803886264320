import React, { Component } from 'react';
import lead from './LeadPeople.json';
import '../StandardPage/StandardPage.css';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import MetaTags from 'react-meta-tags';
import './Lead.css';

class Lead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leadList: {},
      search: {
        query: '',
        queryType: 'name',
        queryMandat: '2024-2028',
        queryYear: '2024'
      }
    };
  }

  componentDidMount() {
    this.searchMandate();
  }

  updateField = event => {
    const field = event.target.name;
    const search = this.state.search;
    search[field] = event.target.value;

    this.setState({
      search
    });
  };

  updateQueryMandatField = event => {
    const field = event.target.name;
    const search = this.state.search;
    search[field] = event.target.value;

    this.setState(
      {
        search
      },
      () => {
        this.searchMandate();
      }
    );
  };

  searchMandate = () => {
    let leadListJson = lead;

    leadListJson = leadListJson.filter(leadMandat => {
      return (
        leadMandat.mandat
          .toLowerCase()
          .indexOf(this.state.search.queryMandat.toLowerCase()) !== -1
      );
    });

    let leadList = { ...this.state.leadList };

    leadList = leadListJson[0];

    this.setState({ leadList }, () => {
      let searchQuery = { ...this.state.searchQuery };
      searchQuery.query = '';
      searchQuery.year = leadList.mandatStartYear;
      searchQuery.queryType = 'name';
      this.setState({ searchQuery });
    });
  };

  search = () => {
    let leadList = { ...this.state.leadList };

    if (!this.state.search.query) {
      this.searchMandate();

      return;
    }

    leadList.people = leadList.people.filter(leadPerson => {
      return (
        leadPerson[this.state.search.queryType]
          .toLowerCase()
          .indexOf(this.state.search.query.toLowerCase()) !== -1
      );
    });

    this.setState({ leadList });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Conducere</title>
          <meta
            name="description"
            content="Conducere Sucevita. Aici este prezentata lista angajatilor primaria Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Conducere" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Conducere </h4>
          </div>
          <h2 className="standard__main-title">Conducere</h2>
        </div>

        <div className="mariage_publications">
          <div className="mariage-publications__page__search standard__search-general-area">
            <div className="lead-page__search-area mariage-publications__search-area">
              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.search.queryMandat}
                    onChange={this.updateQueryMandatField}
                    inputProps={{
                      name: 'queryMandat'
                    }}
                  >
                    <option value="2016-2020">2016-2020</option>
                    <option value="2020-2024">2020-2024</option>
                    <option value="2024-2028">2024-2028</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="standard__input-box mariage-publications__input-box">
                <input
                  required
                  className="standard__input mariage-publications__input "
                  placeholder="Cautare demnitar"
                  name="query"
                  onChange={this.updateField}
                  value={this.state.search.query}
                />
              </div>

              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl className="standard__input ">
                  <NativeSelect
                    value={this.state.search.queryType}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'queryType'
                    }}
                  >
                    <option value="name">Nume Functionar</option>
                    <option value="function">Functie</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.search.queryYear}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'queryYear'
                    }}
                  >
                    <option value="2020">2020</option>
                    <option value="2022">2022</option>
                    <option value="2024">2024</option>
                  </NativeSelect>
                </FormControl>
              </div>

              <button
                className="standard__button mariage-publications__button lead-page__search-button"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => this.search()}
              >
                <h5 className="standard__button-writing mariage-publications__button-writing">
                  {' '}
                  CAUTARE
                </h5>
              </button>
            </div>
          </div>
          <div className="mariage-publications__search-results standard__search-general-area">
            <table className="mariage-publications__table">
              <thead>
                <tr>
                  <td
                    width="100px"
                    className="mariage-publications__table-title"
                  >
                    Nume functionar
                  </td>
                  <td
                    width="100px"
                    className="mariage-publications__table-title"
                  >
                    Functie
                  </td>
                  <td
                    width="100px"
                    className="mariage-publications__table-title"
                  >
                    Declaratie de avere
                  </td>
                </tr>
              </thead>
              <tbody>
                {this.state.leadList.people &&
                this.state.leadList.people.length ? (
                  this.state.leadList.people.map(leadPerson => (
                    <tr key={leadPerson.id}>
                      <td className="mariage-publications__table-title">
                        <h4 className="mariage-publications__wed-name">
                          {leadPerson.name}
                        </h4>
                      </td>
                      <td className="mariage-publications__table-title">
                        <h4 className="mariage-publications__wed-name">
                          {leadPerson.function}
                        </h4>
                      </td>
                      <td className="mariage-publications__table-title mariage-publications__view-more">
                        {leadPerson.statements &&
                          leadPerson.statements.map(leadStatement =>
                            leadStatement.year ===
                              this.state.search.queryYear &&
                            leadStatement.statementUrl.length ? (
                              <h4
                                key={leadStatement.id}
                                className="mariage-publications__view-more"
                                onClick={() =>
                                  window.open(leadStatement.statementUrl)
                                }
                              >
                                {' '}
                                Vizualizeaza
                              </h4>
                            ) : (
                              <div key={leadStatement.id} />
                            )
                          )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      <h4 className="mariage-publications__wed-name">
                        Mandatul nu are oficiali atasati momentan
                      </h4>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default Lead;
