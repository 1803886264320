import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './MariagePublications.css';
import MetaTags from 'react-meta-tags';
import { mariagePublicationsService } from '../../services/MariagePublicationsService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

class MariagePublications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchMariagePublication: {
        wife: '',
        husband: '',
        archiveYear: 2024
      },
      currentMariagePublications: [],
      pagination: {}
    };
  }

  componentWillMount() {
    mariagePublicationsService
      .getAll()
      .then(jsonResponse => {
        this.setMariagePublicationsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setMariagePublicationsState = response => {
    let currentMariagePublications = {
      ...this.state.currentMariagePublications
    };
    let pagination = { ...this.state.pagination };
    currentMariagePublications = response.mariage_publications;
    pagination = response.pagination;

    this.setState({ currentMariagePublications });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchMariagePublication = this.state.searchMariagePublication;
    searchMariagePublication[field] = event.target.value;

    this.setState(
      {
        searchMariagePublication
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
    console.log(this.state.searchMariagePublication.archiveYear);
  };
  search = (pageNumber = 1) => {
    mariagePublicationsService
      .search(this.state.searchMariagePublication, pageNumber)
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.setMariagePublicationsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  searchPublications = (pageNumber = 1) => {
    mariagePublicationsService
      .search(this.state.searchMariagePublication, pageNumber)
      .then(jsonResponse => {
        this.setMariagePublicationsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Publicatii de casatorie</title>
          <meta
            name="description"
            content="Publicatii de casatorie Sucevita. Aici sunt prezentate publicatiile de casatorie semnate in  Sucevita."
          />
          <meta
            name="keywords"
            content="primaria Sucevita, Sucevita, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social, turism, manastirea sucevita, manastiri bucovina, traditii si obiceiuri"
          />
          <meta property="og:title" content="Publicatii de casatorie" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Starea Civilă </h4>
          </div>
          <h2 className="standard__main-title">Publicații de căsătorie</h2>
        </div>
        <div className="mariage-publications">
          <ul className="monitor__list">
            <h3 className="monitor__subtitle">
              Din data de 01.06.2021, Monitorul Oficial Local al comunei
              Sucevita poate fi vizualizat{' '}
              <a
                href="https://emol.ro/sucevita-sv/"
                target="_blank"
                rel="noopener noreferrer"
              >
                aici
              </a>
              .
            </h3>
          </ul>
          <div className="mariage-publications__page__search">
            <div className="mariage-publications__search-area">
              <div className="standard__input-box mariage-publications__input-box">
                <input
                  required
                  className="standard__input mariage-publications__input"
                  label="Sot"
                  placeholder="Soț"
                  name="husband"
                  onChange={this.updateField}
                  value={this.state.searchMariagePublication.husband}
                />
              </div>

              <div className="standard__input-box mariage-publications__input-box">
                <input
                  className="standard__input mariage-publications__input"
                  required
                  label="Sotie"
                  placeholder="Soție"
                  name="wife"
                  onChange={this.updateField}
                  value={this.state.searchMariagePublication.wife}
                />
              </div>
              <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
                <FormControl>
                  <NativeSelect
                    value={this.state.searchMariagePublication.archiveYear}
                    onChange={this.updateField}
                    inputProps={{
                      name: 'archiveYear'
                    }}
                  >
                    <option value="">Arhiva</option>
                    <option value="2024">2024</option>
                    <option value="2023">2023</option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                  </NativeSelect>
                </FormControl>
              </div>
              <button
                className="standard__button mariage-publications__button"
                type="submit"
                variant="contained"
                color="primary"
                onClick={() => this.searchPublications()}
              >
                <h5 className="standard__button-writing mariage-publications__button-writing">
                  {' '}
                  CĂUTARE
                </h5>
              </button>
            </div>
          </div>

          {this.state.currentMariagePublications.length > 0 ? (
            <div className="mariage-publications__search-results">
              <table className="mariage-publications__table">
                <thead>
                  <tr>
                    <td
                      width="100px"
                      className="mariage-publications__table-title"
                    >
                      Sot/Sotie
                    </td>
                    <td
                      width="100px"
                      className="mariage-publications__table-title"
                    >
                      Data inregistrare
                    </td>
                    <td
                      width="100px"
                      className="mariage-publications__table-title"
                    >
                      Data casatorie
                    </td>
                    <td
                      width="100px"
                      className="mariage-publications__table-title"
                    >
                      Vizualizeaza documentul
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {this.state.currentMariagePublications.map(
                    mariagePublication => (
                      <tr key={mariagePublication.id}>
                        <td className="mariage-publications__table-title">
                          <div className="mariage-publications__weds">
                            <h4 className="mariage-publications__wed-name">
                              {mariagePublication.husband} /{' '}
                            </h4>
                            <h4 className="mariage-publications__wed-name">
                              {mariagePublication.wife}
                            </h4>
                          </div>
                        </td>
                        <td
                          width="100px"
                          className="mariage-publications__table-title"
                        >
                          {mariagePublication.registration_date}
                        </td>
                        <td className="mariage-publications__table-title">
                          {mariagePublication.mariage_date}
                        </td>
                        <td
                          className="mariage-publications__table-title mariage-publications__view-more"
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_API_URL}${mariagePublication.document.url}`
                            )
                          }
                        >
                          <h4 className="mariage-publications__view-more">
                            Vizualizeaza
                          </h4>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="standard__no-projects-box">
              <p className="standard__no-projects">
                Nu sunt rezultate pentru căutare.
              </p>
            </div>
          )}
          <div className="mariage-publications__scrollbar">
            <div className="force-overflow" />
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default MariagePublications;
